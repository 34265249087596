import React from "react";
import moment from "moment";
import { Close } from '@mui/icons-material';
import { apiAction } from "../../../../api/api";
import { getPointsBreakDown } from "../../../../api/urls";
import { notifyErrorMessage } from "../../../../utils/Utils";
import { Avatar, CircularProgress, Drawer } from '@mui/material';
import { getWorkspaceInfo } from "../../../../config/cookiesInfo";

const RewardPointsTimeline = ({ data, open, onClose }) => {
    const workspace = getWorkspaceInfo();
    const [pointsDistributionList, setPointsDistributionList] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    const fetchPointDistributionList = async () => {
        try {
            setIsLoading(true);
            let res = await apiAction({
                url: getPointsBreakDown(data.from_date, data.to_date, workspace.work_id, data.emp_id),
                method: 'get'
            });
            setIsLoading(false);
            if (res.success) {
                setPointsDistributionList(res.breakdown);
            } else {
                notifyErrorMessage(res.message);
            }
        } catch (error) {
            setIsLoading(false);
            console.log("Error Fetching Points Distribution:", error);
        }
    };

    React.useEffect(() => {
        fetchPointDistributionList();
    }, []);

    return (
        <Drawer
            open={open}
            elevation={5}
            anchor={'right'}
            onClose={() => onClose(false)}
            variant={"temporary"}
            PaperProps={{
                sx: {
                    zIndex: 999,
                    backgroundColor: '#FFFFFF',
                    width: {
                        xs: '100vw',
                        sm: '35vw',
                    },
                    overflow: "hidden"
                }
            }}
        >
            <div className="flex flex-col h-full">
                <Header onClose={onClose} />

                {isLoading ? (
                    <div className="flex justify-center items-center flex-grow">
                        <CircularProgress />
                    </div>
                ) : pointsDistributionList?.length > 0 ? (
                    <PointsTimeline pointsDistributionList={pointsDistributionList} />
                ) : (
                    <div className="flex justify-center items-center flex-grow">
                        <span className="font-quicksand font-medium text-gray-500">
                            No points distribution data available.
                        </span>
                    </div>
                )}
            </div>
        </Drawer>
    );
};

const Header = ({ onClose }) => (
    <div className="flow-root">
        <div className="flex items-start justify-between px-4 sm:px-6 py-2">
            <div className="text-base font-semibold text-gray-900">Points Distribution</div>
            <div className="ml-3 flex h-7 items-center">
                <button
                    type="button"
                    onClick={() => onClose(false)}
                    className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                    <span className="absolute -inset-2.5" />
                    <span className="sr-only">Close panel</span>
                    <Close aria-hidden="true" className="size-6 outline-none" />
                </button>
            </div>
        </div>
        <div className="w-full border-t border-gray-300" />
    </div>
);

const PointsTimeline = ({ pointsDistributionList }) => (
    <ul role="list" className="-mb-8 px-4 py-6">
        {pointsDistributionList.map((data, eventIdx) => (
            <li key={data.id}>
                <div className="relative pb-8">
                    {eventIdx !== pointsDistributionList.length - 1 ? (
                        <span aria-hidden="true" className="absolute left-3 top-4 -ml-px h-full w-0.5 bg-gray-200" />
                    ) : null}
                    <div className="relative flex space-x-3 items-center">
                        <div>
                            <Avatar
                                sx={{
                                    m: -1,
                                    background: data.task !== null ? "#e0f7fa" : "#fff8e1",
                                    height: "35px",
                                    width: "35px"
                                }}
                            >
                                <span className="rounded-full tracking-wide fill-inherit m-[-1] text-blueGray-800">
                                    {data.points_acquired}
                                </span>
                            </Avatar>
                        </div>
                        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                            <div>
                                <p className="text-sm font-quicksand font-medium">
                                    {data.points_info}
                                    <br />
                                    {data?.points_additional_info && data.points_additional_info !== "null" && (
                                        <span className="text-gray-500">Additional Info:  {data?.points_additional_info}</span>
                                    )}
                                </p>
                                {data?.task !== null &&
                                    <p className="text-sm text-gray-600">
                                        <strong>Task:</strong> {data?.task_description}
                                    </p>
                                }
                            </div>
                            <div className="whitespace-nowrap text-right text-sm text-gray-500">
                                <time dateTime={data.points_time}>
                                    {moment(data.points_time).format('MMM Do YYYY hh:mm a')}
                                </time>
                                {data?.task !== null &&
                                    <p className="text-xs text-gray-600">
                                        <strong>Time Taken:</strong> {data?.time_taken} %
                                    </p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        ))}
    </ul>
);

export default RewardPointsTimeline;