import React from "react";
import { Box, Modal } from '@mui/material';
import Dropdown from "../Dropdown/Dropdown";
import { apiAction } from "../../../api/api";
import CustomLabel from "../Elements/CustomLabel";
import GidInput from "../Elements/inputs/GidInput";
import GIDTextArea from "../Elements/inputs/GIDTextArea";
import PlainButton from "../Elements/buttons/PlainButton";
import { getWorkspaceInfo } from "../../../config/cookiesInfo";
import { employee, getCommentOptionsUrl, manageManualPointsUrl } from "../../../api/urls";
import { isFormValid, notifyErrorMessage, notifySuccessMessage } from "../../../utils/Utils";

const AddManualPointsModal = (props) => {

    const { showModal, setShowModal, taskData = {}, fromScreen, action = "add", fetchManualPointsList = null } = props

    const { work_id } = getWorkspaceInfo();

    const [commentOptions, setCommentOptions] = React.useState([{ points_info: "Select a comment" }]);
    const [listOfEmployees, setEmployees] = React.useState([]);

    const [formData, setFormData] = React.useState({
        task_id: taskData?.id,
        employee: taskData?.employee,
        workspace_id: work_id,
        info_id: null,
        points: 0,
        action: "add",
        additional_info: "",
    })

    let selectedComment = commentOptions.find((info_id) => info_id.id === formData.info_id);
    let selectedEmployee = listOfEmployees.find((emp) => emp.id === formData.employee);

    const getCommentOptions = async () => {
        let res = await apiAction({
            method: 'get',
            url: getCommentOptionsUrl()
        })
        if (res.success) {
            setCommentOptions([{ points_info: 'Select a comment' }, ...res.table])
        }
    }

    const manageManualPoints = async () => {
        let validation_data = [
            { key: "info_id", message: 'Comment field left empty!' },
            { key: "points", message: 'Points field left empty!' },
            { key: "employee", message: 'Employee field left empty' }
        ]
        const { isValid, message } = isFormValid(formData, validation_data);
        if (formData.points === 0) {
            notifyErrorMessage("Points cannot be zero")
            return
        }
        if (formData?.info_id === 38 && !formData?.additional_info) {
            notifyErrorMessage("Additional info is required when entering point manually")
            return
        }
        if (isValid) {
            let res = await apiAction({
                method: 'post',
                url: manageManualPointsUrl(),
                data: { ...formData, action: action === "add" ? "add" : action === "edit" ? "modify" : "delete" },
            })
            if (res.success) {
                setShowModal(false);
                fromScreen === "rewardPointsListing" && fetchManualPointsList();
                notifySuccessMessage(res.message);
            } else {
                notifyErrorMessage(res.message)
            }
        } else {
            notifyErrorMessage(message)
        }
    }

    const getEmployeeResultsApi = async () => {
        let res = await apiAction({
            url: employee(work_id),
            method: 'get',
        })
        if (res.success) {
            setEmployees([{ employee_name: 'All' }, ...res.results])
        }
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 15,
        borderRadius: 1,
        overflow: 'auto'
    }

    React.useEffect(() => {
        getCommentOptions();
        getEmployeeResultsApi();
    }, [])

    React.useEffect(() => {
        // Prefilling data for edit and delete.
        // using following if condition because the keys passed from the dashboard page is different from the rewards points listing page.
        if (fromScreen !== "dashboard") {
            setFormData((previous) => ({
                ...previous,
                task_id: taskData?.task,
                employee: taskData?.employee,
                workspace_id: work_id,
                info_id: taskData?.points_infokey || null,
                points: taskData?.points_acquired || 0,
                additional_info: taskData.points_additional_info || "",
                points_id: taskData?.id || null
            }))
        }
    }, [taskData])

    return (
        showModal ? (
            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} >
                    <div>
                        <div className="flex items-center flex-row h-14  justify-between  border-solid border-slate-200 rounded-t text-black">
                            <h3 className="text-lg font-quicksand font-bold text-center w-full">
                                {(action === "add" ? "Add" : action === "edit" ? "Edit" : "Delete") + " Manual Points"}
                            </h3>
                            <button
                                className="text-lg w-10 h-10 ml-auto rounded-full focus:outline-none hover:bg-gray-200 flex justify-center items-center"
                                onClick={() => setShowModal(false)}>
                                <i className="fa-solid fa-times"></i>
                            </button>
                        </div>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="relative px-5 pt-2 flex-auto">
                                {fromScreen === "rewardPointsListing" &&
                                    <div className="my-4 flex flex-col">
                                        <CustomLabel className={`mb-1 font-quicksand font-semibold text-sm`} label={"Employee"} />
                                        <Dropdown
                                            disabled={action !== "add"}
                                            options={listOfEmployees}
                                            optionLabel="employee_name"
                                            value={selectedEmployee ? selectedEmployee : { employee_name: 'All Users' }}
                                            setValue={(value) => setFormData((previous) => ({ ...previous, employee: value ? value.id : null }))}
                                        />
                                    </div>
                                }
                                <div className="my-4 flex flex-col">
                                    <CustomLabel className={`mb-1 font-quicksand font-semibold text-sm`} label={"Select Comment"} />
                                    <Dropdown
                                        disabled={action === "delete"}
                                        placeholder={true}
                                        options={commentOptions}
                                        optionLabel={"points_info"}
                                        value={selectedComment ? selectedComment : { points_info: "Select a comment" }}
                                        setValue={(value) => setFormData((previous) => ({ ...previous, info_id: value ? value.id : null, points: value ? value.points_value : null }))}
                                    />
                                </div>

                                <div className={`mt-4 flex flex-col `}>
                                    <CustomLabel label={`Points`} className={'font-quicksand font-semibold text-sm mb-1'} />
                                    <GidInput
                                        inputType={"number"}
                                        id='link_description'
                                        value={formData.points}
                                        placeholderMsg={"Enter points"}
                                        disable={formData.info_id !== 38 || action === "delete"}
                                        className={`flex ${formData?.info_id !== 38 ? "bg-gray-200 text-gray-500 cursor-not-allowed" : "bg-white"}`}
                                        onBlurEvent={(e) => { }}
                                        onTextChange={(e) => { setFormData(prev => ({ ...prev, points: e.target.value })) }}
                                    />
                                </div>

                                <div className="my-4 flex flex-col">
                                    <CustomLabel label={`Additional Info`} className={'font-quicksand font-semibold text-sm mb-1'} />
                                    <GIDTextArea
                                        className={"h-20"}
                                        id={"task_detailed_description"}
                                        disable={action === "delete"}
                                        value={formData.additional_info}
                                        onBlurEvent={() => { }}
                                        placeholderMsg={"Add any additional info"}
                                        onTextChange={(event) => setFormData((previous) => ({ ...previous, additional_info: event.target.value }))}
                                    >
                                    </GIDTextArea>
                                </div>
                            </div>

                            <div className="p-6 border-solid border-slate-200 rounded-b">
                                <PlainButton
                                    title={(action === "add" ? "Add" : action === "edit" ? "Update" : "Delete") + " Points"}
                                    className={"w-full"}
                                    onButtonClick={manageManualPoints}
                                    disable={false}
                                />
                            </div>
                        </form>
                    </div>
                </Box>
            </Modal>
        ) : null
    )
}

export default AddManualPointsModal;