import React from "react";
import { apiAction } from "../../../../api/api";
import Dropdown from "../../../custom/Dropdown/Dropdown";
import CircularProgress from '@mui/material/CircularProgress';
import { getWorkspaceInfo } from "../../../../config/cookiesInfo";
import PlainButton from "../../../custom/Elements/buttons/PlainButton";
import { employee, getManualPointsListUrl } from "../../../../api/urls";
import AddManualPointsModal from "../../../custom/Model/AddManualPointsModal";
import { notifyErrorMessage } from "../../../../utils/Utils";

const RewardPointsTable = ({ headers, rowData, onEditClick, setAction }) => {

    return (
        <>
            <div className="px-0 py-4 block h-full overflow-auto">
                <table className="items-center w-full bg-transparent border-collapse rounded-xl shadow-lg">
                    <thead className='bg-gray-200'>
                        <tr className='h-10'>
                            {headers?.map((header, index) => (
                                <th key={index} className={`text-sm p-3 text-left text-blueGray-500 font-interVar font-bold font-quicksand`}>
                                    {header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {rowData?.map((item, index) => {
                            return (
                                <tr key={index} className={`${index % 2 === 0 ? "bg-gray-100" : "bg-white"} hover:bg-blue-100`}>
                                    <td className="px-4 py-4">
                                        <div className='flex items-center'>
                                            <span className="font-quicksand font-medium text-sm">
                                                {index + 1}
                                            </span>
                                        </div>
                                    </td>
                                    <td className="px-4 py-4">
                                        <div className="font-quicksand font-medium text-sm">
                                            {item.employee_name}
                                        </div>
                                    </td>
                                    <td className="px-4 py-4">
                                        <div className="font-quicksand font-medium text-sm">
                                            {item.points_acquired}
                                        </div>
                                    </td>
                                    <td className="px-4 py-4">
                                        <div className="font-quicksand font-medium text-sm">
                                            {item.points_by_name}
                                        </div>
                                    </td>
                                    <td className="px-4 py-4">
                                        <div className="flex justify-stretch">
                                            <svg
                                                viewBox="0 0 1024 1024"
                                                fill="currentColor"
                                                height="1em"
                                                width="1em"
                                                className="cursor-pointer hover:fill-blue-500"
                                                onClick={() => {
                                                    onEditClick(item)
                                                    setAction("edit")
                                                }}
                                            >
                                                <path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 000-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 009.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z" />
                                            </svg>
                                            <svg
                                                viewBox="0 0 24 24"
                                                fill="#af4141"
                                                height="1em"
                                                width="1em"
                                                className="cursor-pointer hover:fill-red-500"
                                                onClick={() => {
                                                    onEditClick(item)
                                                    setAction("delete")
                                                }}
                                            >
                                                <path fill="none" d="M0 0h24v24H0z" />
                                                <path d="M17 6h5v2h-2v13a1 1 0 01-1 1H5a1 1 0 01-1-1V8H2V6h5V3a1 1 0 011-1h8a1 1 0 011 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z" />
                                            </svg>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}

const ManualRewardPoints = () => {

    const workspace = getWorkspaceInfo()

    const [action, setAction] = React.useState("add");
    const [dataToEdit, setDataToEdit] = React.useState();
    const [isLoading, setIsLoading] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState("");
    const [listOfEmployees, setListOfEmployees] = React.useState([]);
    const [manualPointsList, setManualPointsList] = React.useState([]);

    const rewardPointsTableHeaders = ["Sr No.", "Employee", "Points", "Rewarded By", "Actions"];

    const getEmployeeResultsApi = async () => {
        let res = await apiAction({
            url: employee(workspace.work_id),
            method: 'get',
        })
        if (res?.success) {
            setSelectedUser(res.results.find((item, index) => item.id === selectedUser))
            setListOfEmployees([{ employee_name: 'All' }, ...res.results])
        }
    }

    const fetchManualPointsList = async (selecteduser = "") => {
        setIsLoading(true);
        let res = await apiAction({
            url: getManualPointsListUrl(workspace.work_id, selecteduser),
            method: 'get'
        })
        if (res?.success) {
            setIsLoading(false);
            setManualPointsList(res.manual_points);
        }
        else {
            setIsLoading(false);
            notifyErrorMessage(res?.message);
        }
    }

    React.useEffect(() => {
        // not passing id for fetching all employee reward points
        if (selectedUser?.id) {
            fetchManualPointsList(selectedUser?.id)
        } else if (listOfEmployees.length) {
            fetchManualPointsList()
        }
    }, [selectedUser])

    React.useEffect(() => {
        getEmployeeResultsApi();
    }, [])

    return (
        <React.Fragment>
            {Boolean(dataToEdit) &&
                <AddManualPointsModal
                    taskData={dataToEdit || {}}
                    showModal={Boolean(dataToEdit)}
                    setShowModal={() => setDataToEdit()}
                    fromScreen={"rewardPointsListing"}
                    action={action}
                    fetchManualPointsList={() => fetchManualPointsList(selectedUser?.id)}
                />
            }
            <div className='h-full overflow-hidden w-auto pb-32'>
                <div className="bg-white rounded-xl flex shadow p-3 flex-wrap justify-between md:space-x-0 ">
                    <div className='flex flex-wrap md:space-x-2 md:space-y-0 '>
                        <Dropdown
                            options={listOfEmployees}
                            optionLabel="employee_name"
                            value={selectedUser ? selectedUser : { employee_name: 'All Users' }}
                            setValue={(value) => {
                                setSelectedUser(value);
                                setManualPointsList([]);
                            }}
                        />
                    </div>
                    <PlainButton
                        title={"Add"}
                        onButtonClick={() => { setDataToEdit({}); setAction("add") }}
                        className={""}
                        disable={false}
                    />
                </div>

                {isLoading ? (
                    <div className="flex justify-center text-center mt-[35vh]">
                        <span className="font-quicksand font-medium">
                            <CircularProgress />
                        </span>
                    </div>
                ) : (
                    (!manualPointsList?.length) && (
                        <div className="flex justify-center text-center mt-[35vh]">
                            <span className="font-quicksand font-medium">No data to Display.</span>
                        </div>
                    )
                )}

                {
                    manualPointsList?.length > 0 &&
                    <RewardPointsTable
                        headers={rewardPointsTableHeaders}
                        rowData={manualPointsList}
                        onEditClick={(data) => setDataToEdit({ ...data })}
                        setAction={setAction}
                    />
                }
            </div>
        </React.Fragment>
    )
}

export default ManualRewardPoints;