import React from "react";
import moment from "moment";
import { apiAction } from "../../../../api/api";
import { add_reward } from "../../../../utils/Constant";
import { getWorkspaceInfo } from "../../../../config/cookiesInfo";
import IconInput from "../../../custom/Elements/inputs/IconInput";
import ModelComponent from "../../../custom/Model/ModelComponent";
import PlainButton from "../../../custom/Elements/buttons/PlainButton";
import CustomDatePicker from "../../../custom/Elements/CustomDatePicker";
import { getRewardsListUrl, getWinnersListUrl } from "../../../../api/urls";
import { Avatar, CircularProgress, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { formatDate, getDateRange, isFormValid, notifyErrorMessage } from "../../../../utils/Utils";

const RewardsPage = () => {

    const workspace = getWorkspaceInfo();
    const timePeriods = [
        { name: "Current Month", fromDate: getDateRange("Current Month".toLowerCase(), "YYYY-MM-DD", "start"), toDate: getDateRange("Current Month".toLowerCase(), "YYYY-MM-DD", "end") },
        { name: "Previous Month", fromDate: getDateRange("Previous Month".toLowerCase(), "YYYY-MM-DD", "start"), toDate: getDateRange("Previous Month".toLowerCase(), "YYYY-MM-DD", "end") },
        { name: "Current Quarter", fromDate: getDateRange("Current Quarter".toLowerCase(), "YYYY-MM-DD", "start"), toDate: getDateRange("Current Quarter".toLowerCase(), "YYYY-MM-DD", "end") },
        { name: "Previous Quarter", fromDate: getDateRange("Previous Quarter".toLowerCase(), "YYYY-MM-DD", "start"), toDate: getDateRange("Previous Quarter".toLowerCase(), "YYYY-MM-DD", "end") },
        { name: "Custom", fromDate: null, toDate: null }
    ]

    const [selectedTimePeriod, setSelectedTimePeriod] = React.useState(timePeriods[0]);
    const [showAddRewardModal, setShowAddRewardModal] = React.useState(false);
    const [action, setAction] = React.useState("add");
    const [isLoading, setIsLoading] = React.useState(false);
    const [rewardsList, setRewardsList] = React.useState([]);
    const [winnersList, setWinnersList] = React.useState([]);
    const [rowData, setRowData] = React.useState(null);
    const [postData, setPostData] = React.useState({ from_date: selectedTimePeriod.fromDate, to_date: selectedTimePeriod.toDate, workspace_id: workspace.work_id });
    const [tableType, setTableType] = React.useState("Rewards");

    const rewardsTableHeader = ["Sr No.", "Image", "Reward Info", "Reward rank", "Start Date", "End Date", "Added On"];
    const winnersTableHeader = ["Sr No.", "Image", "Reward Info", "Reward rank", "Winner Name", "Start Date", "End Date", "Added On", "Total Points"];

    const validateDate = (fromDate, toDate) => {
        if (fromDate && toDate) {
            const from = moment(fromDate, "YYYY-MM-DD", true);
            const to = moment(toDate, "YYYY-MM-DD", true);

            if (!from.isValid() || !to.isValid()) {
                notifyErrorMessage("Invalid date format");
                return false;
            }

            if (from.isAfter(to)) {
                notifyErrorMessage("Start Date should be less than end Date");
                return false;
            }
        }
        return true;
    };



    const fetchRewardsList = async () => {

        let validation_data = [
            { key: "workspace_id", message: 'Workspace field left empty!' },
            { key: "from_date", message: `Start Date Left empty!` },
            { key: "to_date", message: 'End Date left empty!' },
        ]
        const { isValid, message } = isFormValid(postData, validation_data);

        if (!validateDate(postData.from_date, postData.to_date)) {
            return;
        }

        if (isValid) {
            setIsLoading(true);
            let res = await apiAction({
                method: 'get',
                url: getRewardsListUrl(postData.from_date, postData.to_date, workspace.work_id),
            })
            if (res?.success) {
                setRewardsList(res.rewards);
                setIsLoading(false);
            }
            else {
                setIsLoading(false);
                notifyErrorMessage(res?.detail);
            }
        }
        else {
            setIsLoading(false);
            notifyErrorMessage(message)
        }
    }

    const fetchWinnersList = async () => {
        let validation_data = [
            { key: "workspace_id", message: 'Workspace field left empty!' },
            { key: "from_date", message: `Start Date Left empty!` },
            { key: "to_date", message: 'End Date left empty!' },
        ]
        const { isValid, message } = isFormValid(postData, validation_data);

        if (!validateDate(postData.from_date, postData.to_date)) {
            return;
        }

        if (isValid) {
            setIsLoading(true);
            let res = await apiAction({
                method: 'get',
                url: getWinnersListUrl(postData.from_date, postData.to_date, workspace.work_id),
            })
            if (res?.success) {
                setIsLoading(false);
                setWinnersList(res.winners);
            }
            else {
                setIsLoading(false);
                notifyErrorMessage(res?.detail);
            }
        }
        else {
            setIsLoading(false);
            notifyErrorMessage(message)
        }
    }

    React.useEffect(() => {
        if (!selectedTimePeriod.name.toLocaleLowerCase().includes("custom")) {
            if (tableType === "Rewards") {
                fetchRewardsList();
            } else {
                fetchWinnersList();
            }
        }
    }, [selectedTimePeriod])

    // console.log("postData", postData)

    return (
        <React.Fragment>
            {
                <ModelComponent
                    showModal={showAddRewardModal}
                    setShowModal={setShowAddRewardModal}
                    data={rowData ? rowData : ""}
                    from={"rewards"}
                    action={action}
                    onSuccess={() => fetchRewardsList()}
                />
            }
            <div className='h-full overflow-hidden w-auto pb-32'>
                <div className="bg-white rounded-xl flex shadow p-3 flex-wrap justify-between md:space-x-0 ">
                    <div className='flex flex-wrap md:space-x-2 md:space-y-0 '>
                        <select
                            disabled={false}
                            onChange={(event) => {
                                const selectedItem = JSON.parse(event.target.value)
                                if (!selectedItem.name.toLowerCase().includes("custom")) {
                                    setPostData({ ...postData, from_date: selectedItem.fromDate, to_date: selectedItem.toDate })
                                } else {
                                    setPostData({ ...postData, from_date: "", to_date: "" })
                                }
                                setSelectedTimePeriod(selectedItem)
                            }}
                            // value={JSON.stringify(item.role)}
                            className={`${false ? 'bg-gray-100 ' : ''} cursor-pointer border-blueGray-300 text-blueGray-700 rounded font-quicksand font-semibold text-sm`}
                        >
                            {timePeriods.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {
                                            true && index === 0 ?
                                                <option value={JSON.stringify(item)} className="placeholder-blueGray-200 cursor-pointer font-quicksand font-medium">
                                                    {item.name}
                                                </option>
                                                :
                                                <option value={JSON.stringify(item)} className="text-gray-600 cursor-pointer font-quicksand font-medium">
                                                    {item.name}
                                                </option>
                                        }
                                    </React.Fragment>
                                )
                            }
                            )}
                        </select>

                        {
                            selectedTimePeriod.name != "Custom" &&

                            <div className='flex space-x-2 self-center justify-center items-center pl-2 font-quicksand font-medium text-sm'>
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" fill='#858796'>
                                    <path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z" /></svg>
                                <span className='text-gray-600 font-bold'>From</span>
                                <span className='self-center'>{formatDate(selectedTimePeriod.fromDate, "DD/MM/YYYY")}</span>
                                <span className='text-gray-600 font-bold'>To</span>
                                <span className='self-center'>{formatDate(selectedTimePeriod.toDate, "DD/MM/YYYY")}</span>
                            </div>
                        }

                        {
                            selectedTimePeriod.name === "Custom" &&
                            <div className='flex flex-wrap mt-2 space-x-0 space-y-2 md:mt-0 md:space-x-2 md:space-y-0'>
                                <CustomDatePicker
                                    inputType={"date"}
                                    id='attendance_start_date'
                                    disable={false}
                                    placeholderMsg={""}
                                    className={"md:max-w-[200px] sm:w-full border-none"}
                                    value={postData.from_date}
                                    maxDate={formatDate(postData.to_date, "YYYY-MM-DD")}
                                    onDateChange={(val) => {
                                        setPostData((prev) => ({ ...prev, from_date: val }))
                                    }}
                                />
                                <CustomDatePicker
                                    inputType={"date"}
                                    id='attendance_end_date'
                                    disable={false}
                                    placeholderMsg={""}
                                    className={"md:max-w-[200px] sm:w-full border-none"}
                                    value={postData.to_date}
                                    minDate={formatDate(postData.from_date, "YYYY-MM-DD")}
                                    onDateChange={(val) => {
                                        setPostData((prev) => ({ ...prev, to_date: val }))
                                    }}
                                />

                                <button
                                    className='font-quicksand font-bold text-sm text-white rounded-md border bg-blue-600 px-4 py-2 my-2 md:mt-0'
                                    onClick={() => { tableType === "Rewards" ? fetchRewardsList() : fetchWinnersList() }}
                                >
                                    Submit
                                </button>
                            </div>
                        }
                    </div>

                    <div className="flex justify-start">
                        <div className="flex mr-3">

                            <ToggleButtonGroup
                                value={tableType}
                                exclusive
                                sx={{
                                    height: 40,
                                    '& .MuiToggleButton-root': {
                                        backgroundColor: '#d7edfc',
                                        color: 'black',
                                        '&.Mui-selected': {
                                            backgroundColor: '#2563EB',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: '#2563EB',
                                            },
                                        },
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 135, 255, 0.1)',
                                        },
                                        'outline': "none"
                                    },
                                }}
                                onChange={(e, value) => { if (value !== null) { setTableType(value) } }}
                                aria-label="Table switch"
                            >
                                <ToggleButton value="Rewards">Rewards</ToggleButton>
                                <ToggleButton
                                    value="Winners"
                                    onClick={(e) => { !winnersList.length && fetchWinnersList(); }}
                                >
                                    Winners
                                </ToggleButton>

                            </ToggleButtonGroup>
                        </div>
                        {workspace.role !== "Employee" &&
                            <PlainButton
                                title={"Add Rewards"}
                                onButtonClick={() => { setShowAddRewardModal(add_reward); setAction("add"); setRowData(null) }}
                                className={""}
                                disable={false}
                            />
                        }
                    </div>
                </div>

                {isLoading ? (
                    <div className="flex justify-center text-center mt-[35vh]">
                        <span className="font-quicksand font-medium">
                            <CircularProgress />
                        </span>
                    </div>
                ) : (
                    (!rewardsList?.length) && (
                        <div className="flex justify-center text-center mt-[35vh]">
                            <span className="font-quicksand font-medium">No data to Display.</span>
                        </div>
                    )
                )}

                {(rewardsList?.length > 0 && tableType === "Rewards" && !isLoading) &&
                    <TableComponent
                        headers={rewardsTableHeader}
                        tableData={rewardsList}
                        setShowAddRewardModal={setShowAddRewardModal}
                        tableName={"rewards"}
                        onRowClick={(data) => setRowData(data)}
                        setAction={setAction}
                    />
                }
                {(winnersList?.length > 0 && tableType === "Winners" && !isLoading) &&
                    <TableComponent
                        headers={winnersTableHeader}
                        tableData={winnersList}
                        tableName={"winners"}
                        setShowAddRewardModal={() => ""}
                        onRowClick={() => ""}
                        setAction={() => ""}
                    />
                }
            </div>
        </React.Fragment>
    )
}

const TableComponent = ({ headers, tableData, setShowAddRewardModal, tableName, setAction, onRowClick }) => {
    const workspace = getWorkspaceInfo();
    const [previewImage, setPreviewImage] = React.useState(null);
    const [isPreviewOpen, setIsPreviewOpen] = React.useState(false);

    const handleImageClick = (e, imageUrl) => {
        e.stopPropagation(); // Prevent row click event
        setPreviewImage(imageUrl);
        setIsPreviewOpen(true);
    };

    const closePreview = () => {
        setIsPreviewOpen(false);
        setPreviewImage(null);
    };

    return (
        <React.Fragment>
            {/* Image Preview Modal */}
            <ImagePreviewModal
                imageUrl={previewImage}
                isOpen={isPreviewOpen}
                onClose={closePreview}
            />

            <div className="px-0 py-4 block h-full overflow-auto">
                <table className="items-center w-full bg-transparent border-collapse rounded-xl shadow-lg ">
                    <thead className='bg-gray-200 px-10 '>
                        <tr className='h-10 w-full'>
                            {headers.map((header, index) => (
                                <th key={index} className={`text-sm p-3 text-left text-blueGray-500 font-interVar font-bold font-quicksand`}>
                                    {header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {tableData?.map((data, index) => {
                            return (
                                <tr
                                    // commented onClick logic and "cursor-pointer" css for removing the provision to allocate rewards manually.
                                    key={index}
                                    className={`${index % 2 === 0 ? "bg-gray-100" : "bg-white"} hover:bg-blue-100 ${(tableName === "rewards" && workspace.role !== "Employee") ? "" : ""}`}
                                    onClick={() => {
                                        // if (workspace.role !== "Employee") {
                                        //     setShowAddRewardModal(add_reward); onRowClick(data); setAction("allocate_reward")
                                        // }
                                    }}
                                >
                                    <td className="px-4 py-4">
                                        <div className='flex items-center w-full '>
                                            <span className="font-quicksand font-medium text-sm"></span>
                                            {index + 1}
                                        </div>
                                    </td>

                                    {data?.reward_image ?
                                        <td className="px-4 py-4 ">
                                            <div
                                                className="font-quicksand font-medium text-sm align-top cursor-pointer"
                                                onClick={(e) => handleImageClick(e, data.reward_image)}
                                            >
                                                {imageIcon(data?.reward_image)}
                                            </div>
                                        </td> : <td></td>
                                    }
                                    <td className="px-4 py-4 ">
                                        <div className="font-quicksand font-medium text-sm align-top">
                                            {data?.reward_info}
                                        </div>
                                    </td>
                                    <td className="px-4 py-4 ">
                                        <div className="font-quicksand font-medium text-sm align-top">
                                            {data?.rewards_ranking}
                                        </div>
                                    </td>
                                    {tableName === "winners" &&
                                        <td className="px-4 py-4 ">
                                            <div className="font-quicksand font-medium text-sm align-top">
                                                {data?.winner_name}
                                            </div>
                                        </td>
                                    }
                                    <td className="px-4 py-4 ">
                                        <div className="font-quicksand font-medium text-sm align-top">
                                            {moment(data?.rewards_startdate).format('Do MMM YY')}
                                        </div>
                                    </td>
                                    <td className="px-4 py-4 ">
                                        <div className="font-quicksand font-medium text-sm align-top">
                                            {moment(data?.rewards_enddate).format('Do MMM YY')}
                                        </div>
                                    </td>
                                    <td className="px-4 py-4 ">
                                        <div className="font-quicksand font-medium text-sm align-top">
                                            {moment(data?.rewards_date).format('Do MMM YY, h:mm A')}
                                        </div>
                                    </td>
                                    {tableName === "winners" &&
                                        <td className="px-4 py-4 ">
                                            <div className="font-quicksand font-medium text-sm align-top">
                                                {data?.total_points}
                                            </div>
                                        </td>
                                    }
                                </tr>
                            )
                        }
                        )}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
}


const imageIcon = (avatarUrl) => {
    return (
        <Avatar
            sx={{
                m: -1,
                background: '#f6f6f6',
            }}
        >
            <span className={`rounded-full tracking-wide fill-inherit m-[-1]`}>
                <img
                    src={avatarUrl}
                    alt={"Image"}
                    className="w-10 h-10 rounded-full"
                />
            </span>
        </Avatar>
    )
}


const ImagePreviewModal = ({ imageUrl, isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div
            className="fixed inset-0 backdrop-blur-md bg-gray-100 bg-opacity-30 flex items-center justify-center z-50 p-4"
            onClick={onClose}
        >
            <div className="relative max-w-4xl max-h-screen">
                <button
                    onClick={onClose}
                    className="absolute -top-10 right-0 text-white bg-red-600 rounded-full w-8 h-8 flex items-center justify-center hover:bg-red-700 focus:outline-none"
                    aria-label="Close preview"
                >
                    x
                </button>
                <img
                    src={imageUrl}
                    alt="Preview"
                    className="max-h-[90vh] max-w-full object-contain rounded shadow-xl"
                    onClick={(e) => e.stopPropagation()}
                />
            </div>
        </div>
    );
};
export default RewardsPage;