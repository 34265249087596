import React from "react";
import Dropdown from "../Dropdown/Dropdown";
import { useDropzone } from "react-dropzone";
import CustomLabel from "../Elements/CustomLabel";
import GidInput from "../Elements/inputs/GidInput";
import PlainButton from "../Elements/buttons/PlainButton";
import CustomDatePicker from "../Elements/CustomDatePicker";
import { getWorkspaceInfo } from "../../../config/cookiesInfo";
import { apiAction, apiActionFormData } from "../../../api/api";
import { addRewardUrl, getLeaderBoardsDataUrl } from "../../../api/urls";
import { formatDate, isFormValid, notifyErrorMessage } from "../../../utils/Utils";

const AddRewardsModal = ({ showModal, setShowModal, from, data, action, onSuccess }) => {

    const { work_id } = getWorkspaceInfo();

    const [formData, setFormData] = React.useState({
        work_id: work_id,
        reward_id: null,
        reward_info: "",
        start_date: null,
        end_date: null,
        rank: null,
        employee_id: null,
        total_points: null,
        reward_image: null
    })
    const [listOfEmployees, setEmployees] = React.useState([]);

    let selectedEmployee = listOfEmployees.find((emp) => emp.emp_id === formData.employee_id);

    const getLeaderBoardsEmpList = async () => {

        let res = await apiAction({
            url: getLeaderBoardsDataUrl(data?.rewards_startdate, data?.rewards_enddate, work_id),
            method: 'get',
        })
        if (res?.success) {
            setEmployees([{ employee: 'Select Employee', total_points: 0 }, ...res.leaderboard_total])
        }
    }

    const addReward = async () => {
        let validation_data = [
            action !== "add" && { key: "employee_id", message: 'Please Select an employee.' },
            { key: "reward_info", message: 'Please enter rewards info.' },
            { key: "start_date", message: 'Please select start date.' },
            { key: "end_date", message: 'Please enter end date.' },
            { key: "rank", message: 'Please enter rank.' },
            action !== "add" && { key: "total_points", message: 'Please enter total points.' },
        ];

        const { isValid, message } = isFormValid(formData, validation_data);

        if (isValid) {
            let filteredFormData;

            if (action !== "add") {
                // If action is not "add", send only selected fields
                filteredFormData = {
                    work_id: formData.work_id,
                    reward_id: formData.reward_id,
                    employee_id: formData.employee_id,
                    total_points: formData.total_points,
                };
            } else {
                // else remove null values and send all relevant fields
                filteredFormData = Object.fromEntries(
                    Object.entries(formData).filter(([_, value]) => value != null)
                );
            }

            let res = await apiActionFormData({
                method: "post",
                url: addRewardUrl(),
                data: filteredFormData,
            });

            if (res.success) {
                setShowModal(false);
                onSuccess();
            } else {
                notifyErrorMessage(res.message);
            }
        } else {
            notifyErrorMessage(message);
        }
    };



    const MAX_FILE_SIZE = 3 * 1024 * 1024;

    const { getRootProps, getInputProps } = useDropzone({
        accept: { 'image/jpeg': [], 'image/png': [], 'image/gif': [], 'image/webp': [] },
        multiple: false,
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                const file = acceptedFiles[0];

                if (file.size > MAX_FILE_SIZE) {
                    notifyErrorMessage("File size exceeds 3MB. Please upload a smaller image.");
                    return;
                }

                // Storing both the file and preview information
                setFormData((previous) => ({
                    ...previous,
                    reward_image: file,
                    reward_image_preview: {
                        preview: URL.createObjectURL(file),
                        name: file.name,
                        size: file.size,
                    }
                }));
            }
        },
        onDropRejected: () => {
            notifyErrorMessage("Invalid file type. Please upload an image (JPG, PNG, GIF, WEBP).");
        },
    });


    React.useEffect(() => {
        if (action !== "add") {
            setFormData((previous) => ({
                ...previous,
                reward_id: data?.reward_id,
                reward_info: data?.reward_info,
                reward_image: data?.reward_image,
                start_date: data?.rewards_startdate,
                end_date: data?.rewards_enddate,
                rank: data?.rewards_ranking,
                employee_id: null,
                total_points: null,
            }))
            getLeaderBoardsEmpList();
        }
    }, [])

    // console.log("DATA", data, action)
    // console.log("REWARD FORM DATA", formData)

    return (
        <div>
            <div className="flex items-center flex-row h-14  justify-between  border-solid border-slate-200 rounded-t text-black">
                <h3 className="text-lg font-quicksand font-bold text-center w-full">
                    {action === "add" ? "Add Reward" : "Allocate Reward"}
                </h3>
                <button
                    className="text-lg w-10 h-10 ml-auto rounded-full focus:outline-none hover:bg-gray-200 flex justify-center items-center"
                    onClick={() => setShowModal(false)}>
                    <i className="fa-solid fa-times"></i>
                </button>
            </div>
            <form onSubmit={(e) => e.preventDefault()} >
                <div className="relative px-5 pt-2 flex-auto">
                    {action !== "add" &&
                        <div className="my-4 flex flex-col">
                            <CustomLabel className={`mb-1 font-quicksand font-semibold text-sm`} label={"Employee"} />
                            <Dropdown
                                disabled={false}
                                options={listOfEmployees}
                                optionLabel="employee"
                                value={selectedEmployee ? selectedEmployee : { employee: 'Select Employee' }}
                                setValue={(value) => setFormData((previous) => ({ ...previous, employee_id: value ? value.emp_id : null, total_points: value.total_points }))}
                            />
                        </div>
                    }
                    <div className={`mt-4 flex flex-col `}>
                        <CustomLabel label={`Reward Info`} className={'font-quicksand font-semibold text-sm mb-1'} />
                        <GidInput
                            inputType={"text"}
                            id='rewards_info'
                            value={formData.reward_info}
                            placeholderMsg={"Enter reward info"}
                            disable={action !== "add"}
                            className={`flex ${action !== "add" ? "bg-gray-200 text-gray-500 cursor-not-allowed" : "bg-white"}`}
                            onBlurEvent={(e) => { }}
                            onTextChange={(e) => { setFormData(prev => ({ ...prev, reward_info: e.target.value })) }}
                        />
                    </div>
                    <div className="mt-4 flex flex-row justify-between">
                        <div className="flex flex-col">
                            <CustomLabel label={`Valid From`} className={'font-quicksand font-semibold text-sm mb-1'} />
                            <CustomDatePicker
                                inputType={"date"}
                                id='reward_from_date'
                                disable={action !== "add"}
                                placeholderMsg={""}
                                className={""}
                                value={formData.start_date}
                                maxDate={formatDate(formData.end_date, "YYYY-MM-DD")}
                                onDateChange={(val) => {
                                    setFormData((prev) => ({ ...prev, start_date: val }))
                                }}
                            />
                        </div>

                        <div className="flex flex-col">
                            <CustomLabel label={`Valid Upto`} className={'font-quicksand font-semibold text-sm mb-1'} />
                            <CustomDatePicker
                                inputType={"date"}
                                id='reward_to_date'
                                disable={action !== "add"}
                                placeholderMsg={""}
                                className={""}
                                value={formData.end_date}
                                minDate={formatDate(formData.start_date, "YYYY-MM-DD")}
                                onDateChange={(val) => {
                                    setFormData((prev) => ({ ...prev, end_date: val }))
                                }}
                            />
                        </div>
                    </div>

                    <div className={`mt-4 flex flex-col `}>
                        <CustomLabel label={`Rank`} className={'font-quicksand font-semibold text-sm mb-1'} />
                        <GidInput
                            inputType={"number"}
                            id='reward_rank'
                            value={formData?.rank}
                            placeholderMsg={"Enter reward rank"}
                            disable={action !== "add"}
                            className={`flex ${action !== "add" ? "bg-gray-200 text-gray-500 cursor-not-allowed" : "bg-white"}`}
                            onBlurEvent={(e) => { }}
                            onTextChange={(e) => { setFormData(prev => ({ ...prev, rank: e.target.value })) }}
                        />
                    </div>
                    {(formData.reward_image || action === "add") && (
                        <div className={`mt-4 flex flex-col`}>
                            <CustomLabel label={'Attachment'} className={`font-quicksand font-semibold text-sm mb-1`} />

                            {/* Show Dropzone only when adding a new reward */}
                            {action === "add" && (
                                <div
                                    className={`border-[#c3c3c3] border-dashed border-[1px] h-[50px] flex justify-center items-center rounded-lg cursor-pointer`}
                                    id="drop_zone"
                                    {...getRootProps()}
                                >
                                    <input {...getInputProps()} />
                                    <div className='text-center'>
                                        <i className="fa-solid fa-cloud-arrow-up fa-xl">
                                            <span className='pl-1 text-sm font-quicksand font-semibold'>
                                                Drop Images to attach or click to browse
                                            </span>
                                        </i>
                                    </div>
                                </div>
                            )}

                            {/* Show preview if an image exists */}
                            {formData?.reward_image && (
                                <div className={`mt-2 pt-2 overflow-y-auto max-h-48`}>
                                    <PreviewSection
                                        formData={formData}
                                        setFormData={setFormData}
                                        action={action} // Passing action to control remove button visibility
                                    />
                                </div>
                            )}
                        </div>
                    )}

                    {action !== "add" &&
                        <div className={`mt-4 flex flex-col `}>
                            <CustomLabel label={`Total Points`} className={'font-quicksand font-semibold text-sm mb-1'} />
                            <GidInput
                                inputType={"number"}
                                id='reward_total_points'
                                value={formData.total_points}
                                placeholderMsg={"Enter total points"}
                                disable={true}
                                className={`flex ${action !== "add" ? "bg-gray-200 text-gray-500 cursor-not-allowed" : "bg-white"}`}
                                onBlurEvent={(e) => { }}
                                onTextChange={(e) => { setFormData(prev => ({ ...prev, total_points: e.target.value })) }}
                            />
                        </div>
                    }
                </div>

                <div className="p-6 border-solid border-slate-200 rounded-b">
                    <PlainButton
                        title={action === "add" ? "Add Reward" : "Allocate Reward"}
                        className={"w-full"}
                        onButtonClick={addReward}
                        disable={false}
                    />
                </div>
            </form>
        </div>
    )
}

const PreviewSection = ({ formData, setFormData, action }) => {
    // Determine which image to display
    const imageSrc = formData?.reward_image_preview?.preview || formData?.reward_image;

    if (!imageSrc) return null;

    return (
        <div className="flex items-center space-x-4 border p-2 rounded-lg">
            {/* Image Preview */}
            <img
                src={imageSrc}
                alt="Reward Preview"
                className="w-16 h-16 object-cover rounded-lg border"
            />

            {/* File Name & Size (Only for newly uploaded files) */}
            <div className="flex-1">
                {formData.reward_image_preview ? (
                    <>
                        <a
                            href={formData.reward_image_preview.preview}
                            target='_blank'
                            rel="noreferrer"
                            className='flex w-36 whitespace-nowrap font-semibold hover:text-blue-600 hover:cursor-pointer'
                        >
                            <p className="text-sm font-medium">{formData.reward_image_preview.name}</p>
                        </a>
                        <p className="text-xs text-gray-500">
                            {(formData.reward_image_preview.size / 1024).toFixed(2)} KB
                        </p>
                    </>
                ) : (
                    <p className="text-sm font-medium">uploaded image</p>
                )}
            </div>

            {/* show Remove Button  only if action is add */}
            {action === "add" && (
                <button
                    className="text-red-500 text-xs font-semibold"
                    onClick={() => setFormData((prev) => ({
                        ...prev,
                        reward_image: null,
                        reward_image_preview: null,
                    }))}
                >
                    Remove
                </button>
            )}
        </div>
    );
};


export default AddRewardsModal;